.ag-theme-sp-light {
  --ag-font-family: "Akkurat", sans-serif;
  --ag-foreground-color: #0d0d0dff;
  --ag-active-color: #006d89ff;
  --ag-data-color: #0d0d0dff;

  --ag-advanced-filter-join-pill-color: #f08e8dff;
  --ag-advanced-filter-column-pill-color: #a6e194ff;
  --ag-advanced-filter-option-pill-color: #f3c08bff;
  --ag-advanced-filter-value-pill-color: #85c0e4ff;
  --ag-input-disabled-border-color: #ddddddff;
  --ag-input-disabled-background-color: #181d1f0f;
  --ag-selected-row-background-color: #2196f333;
  --ag-row-hover-color: #2196f333;
  --ag-column-hover-color: #181d1f0d;
  --ag-input-focus-border-color: #2196f3ff;
  --ag-range-selection-background-color: #2196f333;
  --ag-range-selection-background-color-2: #2196f35c;
  --ag-range-selection-background-color-3: #2196f37d;
  --ag-range-selection-background-color-4: #2196f396;
  --ag-secondary-border-color: #babfc7ff;
  --ag-border-color: #babfc7ff;
  --ag-tooltip-background-color: #fbfbfbff;
  --ag-control-panel-background-color: #fbfbfbff;
  --ag-subheader-background-color: #fbfbfbff;
  --ag-subheader-toolbar-background-color: #fbfbfbff;
  --ag-invalid-color: #e02525ff;
  --ag-checkbox-background-color: #f8f8f8ff;
  --ag-checkbox-checked-color: #006d89ff;
  --ag-range-selection-border-color: #006d89ff;
  --ag-secondary-foreground-color: #0d0d0dff;
  --ag-input-border-color: #babfc7ff;
  --ag-input-border-color-invalid: #e02525ff;
  --ag-disabled-foreground-color: #181d1f80;
  --ag-chip-background-color: #181d1f12;
  --ag-side-button-selected-background-color: #fbfbfbff;
  --ag-selected-tab-underline-color: #f8f8f8ff;
  --ag-modal-overlay-background-color: #ffffffa8;
  --ag-value-change-delta-up-color: #43a047ff;
  --ag-value-change-delta-down-color: #e53935ff;
  --ag-checkbox-unchecked-color: #b1b3b3ff;
  --ag-menu-background-color: #fbfbfbff;
}

.ag-theme-sp-light-default {
  @extend .ag-theme-sp-light;
  --ag-background-color: #f1efefff;
  --ag-odd-row-background-color: #f1efefff;
  --ag-header-background-color: #eae8e8ff;
  --ag-header-cell-hover-background-color: #e4e2e2;
}

.ag-theme-sp-light-monitoring {
  @extend .ag-theme-sp-light;
  --ag-background-color: #f1efefff;
  --ag-odd-row-background-color: #f1efefff;
  --ag-header-background-color: #eae8e8ff;
  --ag-header-cell-hover-background-color: #e4e2e2;

  .ag-header,
  .ag-advanced-filter-header {
    background-color: var(--ag-header-background-color);
    border-bottom: solid 2px var(--ag-border-color);
  }
}

.ag-theme-sp-light-paper {
  @extend .ag-theme-sp-light;
  --ag-background-color: #f4f4f4ff;
  --ag-odd-row-background-color: #f4f4f4ff;
  --ag-header-background-color: #eae8e8ff;
  --ag-header-cell-hover-background-color: #e4e1e1;
  --ag-font-size: 12px;
}

.ag-theme-sp-dark {
  --ag-font-family: "Akkurat", sans-serif;
  --ag-foreground-color: #ffffffff;
  --ag-active-color: #299ebdff;
  --ag-data-color: #ffffffff;
  --ag-advanced-filter-join-pill-color: #7a3a37ff;
  --ag-advanced-filter-column-pill-color: #355f2dff;
  --ag-advanced-filter-option-pill-color: #5a3168ff;
  --ag-advanced-filter-value-pill-color: #374c86ff;
  --ag-input-disabled-border-color: #3a434eff;
  --ag-input-disabled-background-color: #68686e12;
  --ag-selected-row-background-color: #2196f333;
  --ag-row-hover-color: #2196f333;
  --ag-column-hover-color: #ffffff0d;
  --ag-input-focus-border-color: #2196f3ff;
  --ag-range-selection-background-color: #2196f314;
  --ag-range-selection-background-color-2: #2196f35c;
  --ag-range-selection-background-color-3: #2196f37d;
  --ag-range-selection-background-color-4: #2196f396;
  --ag-secondary-border-color: #ffffff1a;
  --ag-border-color: #68686eff;
  --ag-tooltip-background-color: #212b38ff;
  --ag-control-panel-background-color: #28313eff;
  --ag-subheader-background-color: #28313eff;
  --ag-subheader-toolbar-background-color: #28313eff;
  --ag-invalid-color: #e02525ff;
  --ag-checkbox-background-color: #030303ff;
  --ag-checkbox-checked-color: #299ebdff;
  --ag-range-selection-border-color: #2196f3ff;
  --ag-secondary-foreground-color: #ffffffff;
  --ag-input-border-color: #68686eff;
  --ag-input-border-color-invalid: #e02525ff;
  --ag-disabled-foreground-color: #ffffff80;
  --ag-chip-background-color: #22262812;
  --ag-side-button-selected-background-color: #030303ff;
  --ag-selected-tab-underline-color: #030303ff;
  --ag-modal-overlay-background-color: #1f2936a8;
  --ag-value-change-delta-up-color: #43a047a8;
  --ag-value-change-delta-down-color: #e53935ff;
  --ag-checkbox-unchecked-color: #797e87ff;
  --ag-menu-background-color: #353e4aff;
}

.ag-theme-sp-dark-default {
  @extend .ag-theme-sp-dark;
  --ag-background-color: #1c1c1cff;
  --ag-odd-row-background-color: #1c1c1cff;
  --ag-header-background-color: #272727;
  --ag-header-cell-hover-background-color: #353535;
}

.ag-theme-sp-dark-monitoring {
  @extend .ag-theme-sp-dark;
  --ag-background-color: #1c1c1cff;
  --ag-odd-row-background-color: #1c1c1cff;
  --ag-header-background-color: #272727;
  --ag-header-cell-hover-background-color: #353535;

  .ag-header,
  .ag-advanced-filter-header {
    background-color: var(--ag-header-background-color);
    border-bottom: solid 2px var(--ag-border-color);
  }
}

.ag-theme-sp-dark-paper {
  @extend .ag-theme-sp-dark;
  --ag-background-color: #030303ff;
  --ag-odd-row-background-color: #030303ff;
  --ag-header-background-color: #1c1c1cff;
  --ag-header-cell-hover-background-color: #2c2c2c;
  --ag-font-size: 12px;
}
